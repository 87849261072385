import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { MarkerType, OperationType, SourceType, UserType } from '../../../app/model/enums.enum';
import { MarkerModel } from '../../../app/model/marker.model';
import { MarkersService } from '../../../app/service/model/markers.service';

import DateUtils from '../../service/util/date-utils';
import { UserModel } from 'src/app/model/user.model';

import * as moment from 'moment';
import { MarkerFilterModel } from './marker.filter.model';
import { first } from 'rxjs/operators';

import { v4 as uuidv4 } from 'uuid';
import { ChannelModel } from 'src/app/model/channel.model';
import { PatrolTeamModel } from 'src/app/model/patrolteam.model';
import { OperationModel } from 'src/app/model/operation.model';
import { DEFAULT_PAGE_INDEX, MARKERS_PAGE_SIZE, SORT_TIMESTAMP_DESC } from 'src/app/common/constants';
import { LoadingListService } from '../../service/loading/loading-list.service';
import { StorageService } from 'src/app/service/storage-service';

// Enumerador explicitar se o componente mostrará
// mensagens de canais ou da operação
export enum MessageComponentType {
  OPERATION_MESSAGE,
  CHANNEL_MESSAGE
}

// Estrutura para encapsular os dados passados para
// o componente de mensagens
export class MessageComponentData {
  operationId: string;
  operationType: string;
  patrolTeamId: string;
  messageSender: UserModel;
  componentType: MessageComponentType;
}

export enum FilterSelectionOptions {
  PATROL,
  VERIFICATION,
  NO_OPERATION
}

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit, OnDestroy, AfterViewInit {

  title: string;

  messageText: string;
  filterSelection: FilterSelectionOptions[];
  filterSelectionOptions = FilterSelectionOptions;

  allMarkers: MarkerModel[];
  filteredMarkers: MarkerModel[];

  /** ENUMS */
  markerType = MarkerType;
  sourceType = SourceType;
  componentType = MessageComponentType;

  dateUtils = DateUtils;

  /** SUBSCRIPTIONS */
  private messageListChangeSubscription: Subscription;
  private onNewMarkerSubscription: Subscription;
  private onMarkerWebsocketConnectedSubscription: Subscription;

  loadingListService: LoadingListService = new LoadingListService();

  /** SCROLL CONTROLS */
  @ViewChild('matListElement', { read: ElementRef }) matListElement: ElementRef;
  @ViewChildren('item') matListItems: QueryList<any>;

  @Output() parentTabUpdate: EventEmitter<string> = new EventEmitter();

  @Output() showMessages = new EventEmitter();

  @Input() loggedUser: UserModel;
  @Input() currentTeam: PatrolTeamModel;
  @Input() currentOperation: OperationModel;
  @Input() markerFilterModel: MarkerFilterModel;
  @Input() selectedComponentType: MessageComponentType;
  @Input() selectedChannel: ChannelModel;

  private matList: any;
  private isNearBottom: boolean;
  showScrollDownButton: boolean;

  lastListItem: any;

  webUserColors: {} = {};
  webColorIndex: number = 0;

  /** PAGINATION CONTROL */
  currentPage: number;
  fetchedPages: number[];

  noMoreMessage: boolean;

  sendingMessage = false;
  
  mediaFilesProcessed: number; // usado ao carregar as mensagens do backend, para conhecer a quantidade de recuperados

  constructor(protected logger: NGXLogger,
    private markerService: MarkersService,
    protected storageService: StorageService,
    private sanitizer: DomSanitizer,
    private changeDetector: ChangeDetectorRef) { }

  //########################################################
  // INITIALIZATION METHODS
  //########################################################

  ngOnInit(): void {

    this.clearData();

    this.subscribeToNewMarkersNotifications();
    this.subscribeOnMarkerWebsocketConnected();

    this.title = this.selectedComponentType === MessageComponentType.CHANNEL_MESSAGE ? 'Selecione o canal' : 'Carregando...'
  }

  ngAfterViewInit() {
    this.matList = this.matListElement.nativeElement; //Obtém o elemento HTML da lista de mensagens
    this.subscribeOnMessageListChange();
  }

  ngOnDestroy(): void {
    this.messageListChangeSubscription?.unsubscribe();
    this.onNewMarkerSubscription?.unsubscribe();
    this.onMarkerWebsocketConnectedSubscription?.unsubscribe();
    this.loadingListService.destroy();
  }

  clearData() {
    this.noMoreMessage = false;
    this.isNearBottom = true;
    this.showScrollDownButton = false;
    this.currentPage = 1;
    this.messageText = '';
    this.fetchedPages = [];
    this.allMarkers = [];
    this.filteredMarkers = [];
    this.filterSelection = [FilterSelectionOptions.PATROL, FilterSelectionOptions.VERIFICATION, FilterSelectionOptions.NO_OPERATION];
  }

  getShowSpinner() {
    return this.loadingListService.getShowSpinner();
  }

  //########################################################
  // SUBSCRIPTIONS
  //########################################################

  /** Se inscreve para receber notificações de mudanças no componente de lista de mensagens */
  private subscribeOnMessageListChange() {
    this.messageListChangeSubscription = this.matListItems.changes.subscribe(() => {
      if (this.matListItems.length < MARKERS_PAGE_SIZE || this.matListItems.last != this.lastListItem) { // Verifica se a mensagem veio do websocket (último item muda) ou paginação
        if (this.isNearBottom) { // Se o scroll estava no final
          this.showScrollDownButton = false;
          this.scrollToPosition(this.matList.scrollHeight); // mantém o scroll no final          
          this.lastListItem = this.matListItems.last; // Atualiza o novo último elemento da lista
        } else {
          this.showScrollDownButton = true; // Se não, mosta o botão flutuante mostrando que existem mensagens novas
        }
        this.renderComponent(); // Força atualização da tela
      }
    }, error => this.logger.error(error));
  }

  private subscribeOnMarkerWebsocketConnected() {
    this.onMarkerWebsocketConnectedSubscription = this.markerService.onMarkerWebsocketConnected().subscribe(() => {
      if (this.selectedChannel || this.currentOperation) {
        // TODO scuri Criar uma nova função que depois de carregar, mas um merge com o que já foi carregado, evitando mudanças no scroll e na tela
        this.loadMarkers();
        this.renderComponent();
      }
    }, (error: any) => this.logger.error(error));
  }

  /** Se inscreve como listener para receber notificações sempre que um marcador de mensagens for recebido */
  private subscribeToNewMarkersNotifications() {
    this.onNewMarkerSubscription = this.markerService.onNewMarkerReceived().subscribe((newMarker: MarkerModel) => {
      if (!newMarker) return;

      //this.logger.debug("NewMarkersNotifications", newMarker);

      // ATENCAO: appointedLocation e serverTimestamp não vem no avro do websocket
      // id vem em objectId
      if (!newMarker.id) {
        newMarker.id = newMarker['objectId'];
      }
      // Location vem separada
      if (!newMarker.location) {
        newMarker.location = { lat: newMarker['latitude'], lng: newMarker['longitude'] };
      }

      if (this.selectedComponentType === MessageComponentType.CHANNEL_MESSAGE) { //Componente listando mensagens de um canal
        if (newMarker.channelId === this.markerFilterModel.channelId) { // Só atualiza se tive o mesmo id do canal
          this.insertMarker(newMarker);
          // comentado para teste de desempenho na produção
          //this.channelService.resetUnreadChannelMessagesdByUser(this.loggedUser.id, this.loggedUser.profileId, this.selectedChannel.id);
        }
      }
      else if (this.currentOperation) { // Componente listando  mensagens de uma operação
        if (this.currentOperation.id === newMarker.operationId && this.currentOperation.type === newMarker.operationType) {  // Só atualiza se a mensagem for desta operação
          this.insertMarker(newMarker);
        }
      }

      this.renderComponent();
    }), (error: any) => {
      let errMsg = (error.message) ? error.message : error.status ? `${error.status} - ${error.statusText}` : 'Server error';
      this.logger.error(`[marker.component.ts] subscribeToNewMarkersNotifications: ${errMsg}`);
    };
  }

  private insertMarker(newMarker: MarkerModel, filter: boolean = true): boolean {
    this.prepareMarkersResources(newMarker);
    let lastMarker = this.allMarkers[this.allMarkers.length - 1]; // ordem cronológica
    const index = this.allMarkers.findIndex(m => m.uuid == newMarker.uuid);
    if (index != -1) {
      if (newMarker.fileId || !this.allMarkers[index].fileId) {
        this.allMarkers[index] = newMarker; // Se já tem aquele marcador apenas atualiza
      }
    }
    else {
      this.allMarkers.push(newMarker);
    }

    let sort: boolean = false;
    if (lastMarker && lastMarker.timestamp > newMarker.timestamp) {// Mensagem antiga, provavelmente ficou presa na lista de sincronismo, necessário reordenar
      this.sortMessages();
      sort = true;
    }

    if (filter) this.filterMarkers();
    
    return sort;
  }

  private sortMessages() {
    this.allMarkers.sort((marker1, marker2) => (marker1.timestamp < marker2.timestamp) ? -1 : 1);
  }

  //########################################################
  // LOAD METHODS
  //########################################################

  public updateTitle(title: string) {
    this.title = title;
  }

  //allOperationMarkers, quando verdadeiro não restringe o número de marcadores ao valor de MARKERS_PAGE_SIZE 
  public loadMarkers(allOperationMarkers?: boolean) {
    this.loadingListService.loadingOn();
    this.markerService.loadFilteredListFromRestApi(DEFAULT_PAGE_INDEX, allOperationMarkers ? null : MARKERS_PAGE_SIZE, SORT_TIMESTAMP_DESC, this.markerFilterModel).pipe(first()).subscribe((markers: MarkerModel[]) => {
      if (markers && markers.length > 0) this.noMoreMessage = false; // Habilita o botão de paginação pq podem existir mais mensagens
      this.mediaFilesProcessed = 0;
      let mediaFiles = markers.filter(marker => (marker.markerType == MarkerType.IMAGE_MESSAGE || marker.markerType == MarkerType.AUDIO_MESSAGE || marker.markerType == MarkerType.VIDEO_MESSAGE));      
      markers.forEach((marker: MarkerModel) => {
        this.prepareMarkersResources(marker, mediaFiles.length);
      });
      this.allMarkers = markers.reverse(); // Não precisa ordenar, nem testar se já existe
      this.filterMarkers(); // Dados de marcadores do backend são sempre filtrados em memória pelo filtro do header      
      //espera terminar o prepareMarkersResources, para avisar que finalizou a troca de canal e, consequentemente renderização do scroll no final da lista de mensagens
      if(mediaFiles.length==0){
        this.endScrollMode();
      }
    },
      error => this.logger.error(error),
      () => {
        this.loadingListService.loadingOff();
        this.renderComponent();
      });
  }

  paginateMarkers() {
    let filteredMarkersLength, newFilteredMarkersLength;
    const found = this.fetchedPages.find(fetchedPage => fetchedPage === this.currentPage); //Verifica se a página já está em memória
    this.scrollToPosition(0); // o scroll vai para o inicio da lista, assim permite que sejam apresentados as mensagens acima
    if (found) {
      return;
    }

    if (this.loadingListService.getSpinnerCount() > 0) return; // evita ser chamado mais de uma vez, enquanto fez a chamada para o backend

    this.loadingListService.loadingOn();
    this.markerService.loadFilteredListFromRestApi(this.currentPage, MARKERS_PAGE_SIZE, SORT_TIMESTAMP_DESC, this.markerFilterModel).pipe(first()).subscribe((markers: MarkerModel[]) => {
      if (markers.length === 0) {
        this.noMoreMessage = true;
        return;
      }

      this.fetchedPages.push(this.currentPage);
      this.currentPage++;

      markers.forEach(marker => {
        this.insertMarker(<MarkerModel>marker, false);
      })

      filteredMarkersLength = this.filteredMarkers.length;
      this.filterMarkers(); // Dados de marcadores do backend são sempre filtrados em memória pelo filtro do header

      newFilteredMarkersLength = this.filteredMarkers.length;
    },
      error => {
        this.logger.error(error)
      },
      () => {
        this.loadingListService.loadingOff();
        if (!this.noMoreMessage && newFilteredMarkersLength == filteredMarkersLength) {
          this.paginateMarkers();
        }
        this.renderComponent();
      });
  }

  private prepareMarkersResources(marker: MarkerModel, mediaFileLength?: number) {

    if (!marker || !marker.fileId) return;

    if (marker.markerType == MarkerType.IMAGE_MESSAGE || marker.markerType == MarkerType.AUDIO_MESSAGE || marker.markerType == MarkerType.VIDEO_MESSAGE) {

      this.markerService.loadFileById(marker.fileId).pipe(first()).subscribe(file => {
        if (file.body.size === 0) return;

        if (marker.markerType == MarkerType.IMAGE_MESSAGE) {
          var reader = new FileReader();
          reader.readAsDataURL(file.body);
          reader.onload = (_event) => {
            marker.fileUrl = reader.result;
          }
        } else if (marker.markerType == MarkerType.AUDIO_MESSAGE) {
          //Creates a new blob with the same data, but with the new type:audio.
          const blob = file.body.slice(0, file.body.size, "audio/mpeg");
          let audio = new Audio();
          audio.src = URL.createObjectURL(blob);
          audio.load();
          marker.fileUrl = this.sanitizer.bypassSecurityTrustUrl(audio.src);
        } else if (marker.markerType == MarkerType.VIDEO_MESSAGE) {
          //Creates a new blob with the same data, but with the new type:video.
          const blob = file.body.slice(0, file.body.size, "video/mp4");
          marker.fileUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob));
        }
        if(mediaFileLength) this.mediaFilesProcessed++;
        if(mediaFileLength && (this.mediaFilesProcessed == mediaFileLength)){
          this.endScrollMode();
        }
      }, error => {
        this.endScrollMode();
        this.logger.error(error);
      });
      
    }
  }

  textKeyPress(event) {
    if (event.keyCode == 13 && !event.shiftKey) {
      //Stops enter from creating a new line
      event.preventDefault();
      this.sendMessage();
      return true;
    }
  }

  sendMessage() {
    if (this.sendingMessage) return; // Evita duplicação de mensagens

    if (!this.messageText || this.messageText.trim() === '') return;

    this.sendingMessage = true;

    const newMarker = this.createMarker();

    const formElements = new Map<string, object>();
    this.markerService.create(newMarker, formElements).pipe(first()).subscribe((marker: MarkerModel) => {
      //this.logger.info('Mensagem enviada: ', marker);
      this.insertMarker(marker);
      this.messageText = '';
      this.renderComponent();
    }, error => this.logger.error(error)
      , () => this.sendingMessage = false);
  }

  private createMarker(): MarkerModel {
    let marker: MarkerModel = new MarkerModel(); // marker.id é null porque ainda não foi criado no backend

    if (!this.loggedUser || !this.loggedUser.id) {
      this.loggedUser = this.storageService.getLocalUser();
    }

    const loggedUserProfile = this.storageService.getLocalProfile();
    const isProfessional = loggedUserProfile.userType == UserType.PROFESSIONAL;
    const isTeamChannel = this.selectedChannel.type == 'PD_MOBILE' || this.selectedChannel.type == 'PD_TECHNICAL';

    marker.message = this.messageText;
    marker.patrolTeamId = this.currentTeam?.id;
    marker.operationId = this.currentOperation?.id;
    marker.operationType = this.currentOperation?.type;
    marker.operationIdentifier = this.currentOperation?.identifier;
    marker.markerType = MarkerType.TEXT_MESSAGE;
    marker.sourceType = SourceType.WEB_APP;
    marker.priority = false;
    marker.location = { lat: 0, lng: 0 };
    marker.timestamp = moment().valueOf();
    marker.serverTimestamp = null; // Não temos essa estimativa na Web
    marker.userId = this.loggedUser.id.toString();
    marker.userName = isTeamChannel && !isProfessional? this.loggedUser.login: this.loggedUser.name; // Esconde o nome de quem não é profissional em canais de equipe
    marker.uuid = uuidv4();
    marker.channelId = this.selectedChannel?.id;
    marker.channelName = this.selectedChannel?.name;

    return marker;
  }

  filterSelectionChanged() {
    this.filterMarkers();
  }

  /** Método que filtra a lista total de marcadores do backend usando o filtro do header */
  private filterMarkers() {
    if (this.allMarkers) {
      this.filteredMarkers = this.allMarkers.filter((marker: MarkerModel) => {
        return this.checkFilterMatchMarker(marker);
      });
    }
  }

  private checkFilterMatchMarker(marker: MarkerModel): boolean {
    let filterMatch: boolean = false;
    this.filterSelection.forEach((filterOptions: FilterSelectionOptions) => {
      switch (filterOptions) {
        case FilterSelectionOptions.PATROL:
          filterMatch = (!filterMatch && marker.operationType && marker.operationType === OperationType.PATROL) ? true : filterMatch;
          break;
        case FilterSelectionOptions.VERIFICATION:
          filterMatch = (!filterMatch && marker.operationType && marker.operationType === OperationType.EVENT_VERIFICATION) ? true : filterMatch;
          break;
        case FilterSelectionOptions.NO_OPERATION:
          filterMatch = (!filterMatch && !marker.operationType) ? true : filterMatch;
          break;
      }
    });
    return filterMatch;
  }

  //########################################################
  // SCROLL CONTROL
  //########################################################

  private scrollToPosition(position: any): void {
    this.matList.scroll({
      top: position,
      left: 0,
      behavior: 'smooth'
    });
  }

  @HostListener('scroll', ['$event'])
  scrolled(event: any): void {
    this.isNearBottom = this.isUserNearBottom();

    if (this.isNearBottom) {
      this.showScrollDownButton = false;
    }
  }

  private isUserNearBottom(): boolean {
    const threshold = 50;
    const position = this.matList.scrollTop + this.matList.offsetHeight;
    const height = this.matList.scrollHeight;
    return position > height - threshold;
  }

  /* Tabela sugerida pelo Bello:
    hsla(344, 80, 73, 1), -- rosa (partner 2)
    hsla(190, 95, 48, 1), -- ciano claro
    hsla(145, 61, 61, 1), -- verde claro
    hsla(32, 95, 48, 1),  -- laranja
    hsla(18, 91, 55, 1),  -- marrom claro
    hsla(344, 80, 53, 1), -- rosa escuro (partner 1)
    hsla(190, 95, 28, 1), -- ciano escuro (self)
    hsla(145, 61, 41, 1), -- verde escuro
    hsla(32, 95, 28, 1),  -- laranja escuro
    hsla(18, 91, 35, 1)   -- marrom escuro
  */

  webColorsMax = 6;

  webColors: string[] = [
    "hsl(145, 61%, 41%)",  // verde escuro
    "hsl(32, 95%, 28%)",   // laranja escuro
    "hsl(190, 95%, 48%)",  // ciano claro
    "hsl(145, 61%, 61%)",  // verde claro
    "hsl(32, 95%, 48%)",   // laranja
    "hsl(18, 91%, 35%)",   // marrom escuro
    "hsl(18, 91%, 55%)",   // marrom claro
  ];

  getUserColor(userId) {
    if (!userId) userId = "null";
    if (this.webUserColors[userId]) return this.webUserColors[userId];

    if (userId == "null") { this.webUserColors[userId] = "#757575"; return this.webUserColors[userId]; } // cinza

    if (userId == this.loggedUser?.id) { this.webUserColors[userId] = "hsl(190, 95%, 28%)"; return this.webUserColors[userId]; } // ciano escuro - self
    if (this.currentTeam && this.currentTeam.users[0] && this.currentTeam.users[0].id == userId) { this.webUserColors[userId] = "hsl(344, 80%, 53%)"; return this.webUserColors[userId]; } // rosa escuro - partner 1
    if (this.currentTeam && this.currentTeam.users[1] && this.currentTeam.users[1].id == userId) { this.webUserColors[userId] = "hsl(344, 80%, 73%)"; return this.webUserColors[userId]; } // rosa  - partner 2
    if (this.currentTeam && this.currentTeam.users[2] && this.currentTeam.users[2].id == userId) { this.webUserColors[userId] = "hsl(344, 80%, 73%)"; return this.webUserColors[userId]; } // rosa  - partner 3

    // Não é proffional, então é alguém da Web, pega a próxima cor disponível
    this.webUserColors[userId] = this.webColors[this.webColorIndex];

    // Incrementa cor disponível
    if (this.webColorIndex < this.webColorsMax)
      this.webColorIndex++;
    else
      this.webColorIndex = 0;

    return this.webUserColors[userId];
  }

  renderComponent() {
    // aguarda um pouco para outras tarefas terminarem
    setTimeout(() => {
      this.changeDetector.detectChanges();
    }, 250);
  }
  
  //Função que é chamada quando é carregado várias mensagens e precisa apresentar o scroll no final
  endScrollMode(){    
    setTimeout(() => {      
      this.scrollToPosition(this.matList.scrollHeight);
    }, 500);
  }

  toggleShowMessages() {
    this.showMessages.emit();
  }
}
