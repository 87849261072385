import { Component, Input, OnInit } from '@angular/core';
import { MatOptionSelectionChange } from '@angular/material/core';
import { Autocomplete } from '../autocomplete/autocomplete.component';

@Component({
  selector: 'multiselect-object-autocomplete',
  templateUrl: './multiselect-object-autocomplete.component.html',
  styleUrls: ['./multiselect-object-autocomplete.component.css']
})
export class MultiselectObjectAutocompleteComponent extends Autocomplete implements OnInit {

  // valueData é dado que vai ser atualizado no model (aqui podem ser vários objetos)
  _valueData: Object[];
  @Input()
  set valueData(data: Object[]) {
    if (!Array.isArray(data)) {
      data = []
    }
    this._valueData = data;
    if (data) {
      this.selectedData = this.getDataControlObjectValue(this._valueData, this._optionValue);
      this.dataControl.setValue(this.selectedData);
    }
  }

  get valueData() {
    return this._valueData;
  }

  selectedData: Object[] = new Array<Object>();

  ngOnInit() {
  }

  displayFn = (value: Object | string): string | undefined => {
    let displayValue: string;
    if (value == null) {
      return '';
    }
    this.selectedData.forEach((data, index) => {
      if (index === 0) {
        displayValue = this.displayData(data)
      } else {
        displayValue += ', ' + this.displayData(data);
      }
    });
    return displayValue;
  }

  displayData = (data: Object) => {
    let displayValue = `[${this.getData(data).opsNumber}] ${this.getData(data).name}`;
    return displayValue;
  }

  optionClicked(event: MatOptionSelectionChange, data: Object) {
    if (event.isUserInput) {
      this.toggleSelection(data);
    }
  } 

  toggleSelection(data: Object) {
    const dataValue = this._name ? data[this._name] : data;
    const index = this.selectedData.findIndex(item => {
      const itemValue = this._name ? item[this._name] : item;
      return itemValue === dataValue;
    });
    if (index >= 0)  {
      this.selectedData.splice(index, 1);
    }
    else {
      this.selectedData.push(data);
    }
    this.valueDataChange.emit(this.getDataControlPropertyValue(this.selectedData, this._optionValue));
  }

  focusInFunction() {
    this.dataControl.setValue(null);
  }

  onOpenedEvent(event) {
  }

  onClosedEvent(event) {
    this.valueDataChange.emit(this.getDataControlPropertyValue(this.selectedData, this._optionValue));
    this.dataControl.setValue(this.selectedData);
    this.selectionChangedEvent.emit(this.selectedData);
  }

  getDataControlObjectValue(value: any[], option: string) {
    let retValue = [];
    if (option) {
      value.forEach(val => {
        this._data.forEach(item => {
          if(item[option] === val) {
            retValue.push(item);
          }
        })
      })
    } 
    else {
      retValue = value;
    }
    return retValue;
  }

  getDataControlPropertyValue(value: any[], option: string) {
    let retValue= [];
    if (option) {
      value.forEach(val => {
        this._data.forEach(item => {
          if(item[option] === val[option]) {
            retValue.push(item[option]);
          }
        })
      })
    }
    else {
      retValue = value;
    }
    return retValue;
  }

  isSelected(data) {
    const dataValue = this._name ? data[this._name] : data;
    const index = this.selectedData.findIndex(item => {
      const itemValue = this._name ? item[this._name] : item;
      return itemValue === dataValue;
    });
    return index >= 0 ? true : false;
  }
  
  unSelectAll(){
    this.selectedData = [];
  }
}
