<div class="sipd-container">

  <header class="sipd-header">
    <span class="mat-title m-0">Verificação: {{view['identifier']}}</span>
    <div class="m-0 text-emphasis-2">
      <span class="header-tag ml-2" *ngIf="view['status']"> <span>&#9679;</span> {{ getOperationStatus() }}</span>
      <span *ngIf="view['expired'] && view['status']=='FINISHED'"> (expirada)</span>
    </div>
    <div class="fill-space"></div>
    <div class="actions">
      <button mat-raised-button 
        matTooltip="Habilitar Edição"
        [hidden]="isHiddenButtonEditForUser || !readOnly"
        (click)="onEnableEditClick()">
        Habilitar Edição
      </button>
      <button mat-icon-button color="primary"
        matTooltip="Copiar"
        (click)="onCopyClick()"
        [disabled]="!id"
        [hidden]='isHiddenButtonCreateEditVerification'>
        <mat-icon class="material-icons-outlined">file_copy</mat-icon>
      </button>
      <button mat-icon-button color="primary" (click)="onDeleteClick()"
        matTooltip="Remover"
        [disabled]="!isVerificationDeletionPermitted()"
        [hidden]='isHiddenButtonDeleteVerification'>
        <mat-icon class="material-icons-outlined">delete</mat-icon>
      </button>
      <button mat-icon-button matTooltip="Exportar"
        (click)="onExportClick()">
        <mat-icon class="material-icons-outlined">save_alt</mat-icon>
      </button>
      <button mat-icon-button [disabled]="!canArchive()"
        (click)="onArchiveClick()"
        [matTooltip]= "!view['archived'] ? 'Arquivar' : 'Desarquivar'"
        [hidden]="isHiddenButtonArchiveForUser">
        <mat-icon class="material-icons-outlined">archive</mat-icon>
      </button>
      <button mat-icon-button  (click)="onLocationClick()" matTooltip="Mostrar no Mapa" [disabled]="!hasLocation()">
        <mat-icon class="material-icons-outlined">location_on</mat-icon>
      </button>
      <button mat-icon-button matTooltip="Mostrar Rastro no Mapa" [disabled]="!canHasHistoricalTracking()"
        (click)="onHistoricalTrackingClick()">
        <mat-icon class="material-icons-outlined">my_location</mat-icon>
      </button>
      <button mat-icon-button matTooltip="Mostrar Mensagens da Verificação" [disabled]="!canHasHistoricalTracking()"
        (click)="onMessagesClick()">
        <mat-icon class="material-icons-outlined">forum</mat-icon>
      </button>
      <div class="ml-2" (mouseenter)="enterCanSend()" (mouseleave)="leaveCanSend()">
        <button mat-icon-button (click)="onSendClick()" [disabled]="!canSend()"
          matTooltip = "{{isSent()? 'Solicitada': 'Solicitar'}}">
          <mat-icon class="material-icons-outlined">send</mat-icon>
        </button>
      </div>
      <button mat-icon-button  [disabled]="!canRevert()" (click)="onRevertClick()" matTooltip="Desfazer Modificações" [hidden]='isHiddenButtonCreateEditVerification'>
        <mat-icon class="material-icons-outlined">restart_alt</mat-icon>
      </button>
      <div (mouseenter)="enterCanSave()" (mouseleave)="leaveCanSave()">
        <button mat-icon-button [disabled]="!canSave() || km.invalid || latLong.invalid ||
                                                          stretchStartKM.invalid || stretchEndKM.invalid ||
                                                          stretchStartLatLong.invalid || stretchEndLatLong.invalid"
          [hidden]='isHiddenButtonCreateEditVerification'
          (click)="onSaveClick($event)" matTooltip="Salvar">
          <mat-icon class="material-icons-outlined">save</mat-icon>
        </button>
      </div>
    </div>
  </header>

  <mat-divider></mat-divider>

  <div class="sipd-content">
    <mat-tab-group class="-bg-white h-100 -fill-height -overflow mat-accent" mat-align-tabs="start"
      animationDuration="0ms">

      <!-- Identificação (Tab) -->
      <mat-tab label="Identificação">
        <form>
          <div fxLayout="row wrap" fxLayoutGap=".5rem" fxLayoutGapRow="0" class="pt-3 pl-3 pr-2">

            <!-- Analista -->
            <single-autocomplete [data]="entityCacheService.getUsers()" name="name" placeholder="Analista CCPD" [(valueData)]="view['analyst']" [displayName]="getUserNameLoginTitle"
              matTooltip="{{ getUserNameLoginTitle(view['analyst']) }}" [disabled]="!isAdmin() || readOnly" fxFlex>
            </single-autocomplete>

            <mat-form-field appearance="outline" class="no-hint-text mat-form-field-readonly" fxFlex>
              <!-- Evento Associado -->
              <mat-label>Evento Associado</mat-label>
              <input matInput name='eventId' matTooltip="{{ view['eventId'] }}" placeholder="Evento Associado" [(ngModel)]="view['eventId']" readonly>
            </mat-form-field>             
            
            <mat-form-field appearance="outline" class="no-hint-text mat-form-field-readonly" fxFlex>
              <mat-label>Data de Solicitação </mat-label>
              <input matInput mask="d0/M0/0000" name="sentDateDay" [(ngModel)]="view['sentDateDay']" placeholder="Data de Solicitação"  readonly>
            </mat-form-field>
            
            <mat-form-field appearance="outline" class="no-hint-text mat-form-field-readonly" fxFlex>
              <mat-label>Hora de Solicitação </mat-label>
              <input matInput name="sentDateHour" [(ngModel)]="view['sentDateHour']" placeholder="Hora de Solicitação" readonly>
            </mat-form-field>

            <div class="form-spacer" fxFlex>
              <mat-slide-toggle class="my-4 px-4 d-block" color="accent" name="priority" [disabled]="readOnly"
                [checked]="view['priority'] === 'true'" [(ngModel)]="view['priority']">
                Prioritária</mat-slide-toggle>
            </div>

            <single-autocomplete [data]="entityCacheService.getPlacements()" name="name" placeholder="Lotação" [(valueData)]="view['placement']"
              (selectionChangedEvent)="filterByPlacement()" [disabled]="readOnly" fxFlex>
            </single-autocomplete>
            
            <single-autocomplete [data]="filteredCompanies" name="name" placeholder="Empresa" [(valueData)]="view['company']"
              [disabled]="readOnly" (selectionChangedEvent)="filterByCompany()" fxFlex>
            </single-autocomplete>

            <single-autocomplete [data]="serviceTypeDescription | keyvalue" placeholder="Tipo de Serviço" name="value" optionValue="key"
              [(valueData)]="view['serviceType']" (selectionChangedEvent)="filterByService()"
              [disabled]="readOnly" fxFlex>
            </single-autocomplete>

            <single-autocomplete [data]="filteredTeams" name="name" placeholder="Equipe" [(valueData)]="view['patrolTeam']"
              [disabled]="readOnly" fxFlex>
            </single-autocomplete>
          
            <mat-form-field class="no-hint-text mat-form-field-readonly" fxFlex appearance="outline">
              <mat-label>Profissional 1</mat-label>
              <input matInput name="professional1" [value]="getUserNameLoginTitle(view['patrolTeam']?.users[0])" matTooltip="{{ getUserNameLoginTitle(view['patrolTeam']?.users[0]) }}" placeholder="Profissional 1" readonly>
            </mat-form-field>

            <mat-form-field class="no-hint-text mat-form-field-readonly" fxFlex appearance="outline">
              <mat-label>Profissional 2</mat-label>
              <input matInput name="professional2" [value]="getUserNameLoginTitle(view['patrolTeam']?.users[1])" matTooltip="{{ getUserNameLoginTitle(view['patrolTeam']?.users[1]) }}" placeholder="Profissional 2" readonly>
            </mat-form-field>

            <mat-form-field class="no-hint-text mat-form-field-readonly" fxFlex appearance="outline">
              <mat-label>Profissional 3</mat-label>
              <input matInput name="professional3" [value]="getUserNameLoginTitle(view['patrolTeam']?.users[2])" matTooltip="{{ getUserNameLoginTitle(view['patrolTeam']?.users[2]) }}" placeholder="Profissional 3" readonly>
            </mat-form-field>

            <mat-form-field class="no-hint-text mat-form-field-readonly" fxFlex appearance="outline">
              <mat-label>Turno</mat-label>
              <input matInput name="shift" [value]="view['patrolTeam']?.shift ? shiftDescription[view['patrolTeam']?.shift]: ''" matTooltip="{{ shiftDescription[view['patrolTeam']?.shift] }}" placeholder="Turno" readonly>
            </mat-form-field>

            <mat-form-field class="no-hint-text mat-form-field-readonly" fxFlex appearance="outline">
              <mat-label>Placa</mat-label>
              <input matInput name="vehicle" [value]="view['patrolTeam']?.vehicle?.plate ? view['patrolTeam']?.vehicle?.plate: ''" placeholder="Placa" matTooltip="{{ view['patrolTeam']?.vehicle?.plate }}" readonly>
            </mat-form-field>

            <mat-form-field class="no-hint-text mat-form-field-readonly" fxFlex appearance="outline">
              <mat-label>Coordenador</mat-label>
              <input matInput name="coordinatorName" [value]="getUserNameLoginTitle(view['patrolTeam']?.patrolCoordinator)" placeholder="Coordenador" matTooltip="{{ getUserNameLoginTitle(view['patrolTeam']?.patrolCoordinator) }}" readonly>
            </mat-form-field>          

            <!--Formulário-->
            <multiselect-autocomplete [data]="entityCacheService.getPublishedForms()" name="name" placeholder="Formulários"
              [displayName]="getFormNameAndInspection" [(valueData)]="view['forms']" [disabled]="readOnly" fxFlex>
            </multiselect-autocomplete>
            
            <div class="form-spacer" fxFlex></div>
            <div class="form-spacer" fxFlex></div>
            <div class="form-spacer" fxFlex></div>
            <div class="form-spacer" fxFlex></div>
            <div class="form-spacer" fxFlex></div>
          </div>

          <!-- Detalhes para a Equipe -->
          <div class="w-100 px-3">
            <mat-form-field appearance="outline" fxFlex>
              <mat-label>Detalhes para a Equipe</mat-label>
              <textarea name="text" matInput [(ngModel)]="view['observations']" placeholder="Detalhes para a Equipe" rows="5" [readonly]="readOnly"></textarea>
            </mat-form-field>
          </div>
        </form>
      </mat-tab>

      <!-- Localização (Tab) -->
      <mat-tab label="Localização">
        <form class="edit-form h-100">

          <div fxLayout="column" class="h-100">
            <div fxLayout="row wrap" fxLayoutGap=".5rem" fxLayoutGapRow="0" class="pt-3 pl-3 pr-2">

              <!-- Faixa -->
              <single-autocomplete [data]="singleDataCacheService.getValues('Band')" placeholder="Faixa" [(valueData)]="view['band']"
                [extraData]="model && model['band'] ? model['band'] : null"
                [disabled]="readOnly" matTooltip="{{ view['band'] }}" fxFlex>
              </single-autocomplete>

              <!-- Trecho -->
              <single-autocomplete [data]="singleDataCacheService.getValues('PipeStretch')" placeholder="Trecho" [(valueData)]="view['stretch']"
                [extraData]="model && model['stretch'] ? model['stretch'] : null"
                [disabled]="readOnly" matTooltip="{{ view['stretch'] }}" fxFlex>
              </single-autocomplete>

              <!-- Duto -->
              <single-autocomplete [data]="singleDataCacheService.getValues('Duct')" placeholder="Duto" [(valueData)]="view['duct']"
                [extraData]="model && model['duct'] ? model['duct'] : null"
                [disabled]="readOnly" matTooltip="{{ view['duct'] }}" fxFlex>
              </single-autocomplete>

              <!-- Válvula -->
              <single-autocomplete [data]="singleDataCacheService.getValues('Valve')" placeholder="Válvula" [(valueData)]="view['valve']"
                [extraData]="model && model['valve'] ? model['valve'] : null"
                [disabled]="readOnly" matTooltip="{{ view['valve'] }}" fxFlex>
              </single-autocomplete>

              <!-- KM -->
              <mat-form-field appearance="outline" fxFlex>
                <mat-label>KM</mat-label>
                <input [readonly]="readOnly" name="km" matInput matTooltip="{{ view['km'] }}" placeholder="KM" [(ngModel)]="view['km']"
                  #km="ngModel" [dropSpecialCharacters]="false" mask="000+000" [validation]="true"/>
                <mat-error *ngIf="km.invalid">Formato inválido para KM.</mat-error>
              </mat-form-field>

              <!-- KM (Lat, Long) -->
              <mat-form-field appearance="outline" fxFlex>
                <mat-label>KM (Lat, Long)</mat-label>
                  <input [readonly]="readOnly" name="latLong" matInput placeholder="KM (Lat, Long)" matTooltip="{{ view['latLong'] }}"
                    [(ngModel)]="view['latLong']" [mask]="latLongMask" (paste)="onPasteLatLong($event, 'latLong')"
                    [patterns]="latLongPattern" #latLong="ngModel" [dropSpecialCharacters]="false" [validation]="true" />
                  <mat-error *ngIf="latLong.invalid">Formato inválido para KM (Lat, Long) .</mat-error>
              </mat-form-field>

              <!-- Início Trecho (KM) -->
              <mat-form-field appearance="outline" fxFlex>
                <mat-label>Início Trecho (KM)</mat-label>
                <input [readonly]="readOnly" name="stretchStartKM" matInput placeholder="Início Trecho (KM)" matTooltip="{{ view['stretchStartKM'] }}"
                  [(ngModel)]="view['stretchStartKM']" #stretchStartKM="ngModel" [dropSpecialCharacters]="false" mask="000+000" [validation]="true" />
                <mat-error *ngIf="stretchStartKM.invalid">Formato inválido o Início do trecho (KM).</mat-error>
              </mat-form-field>

              <!-- Fim Trecho (KM) -->
              <mat-form-field appearance="outline" fxFlex>
                <mat-label>Fim Trecho (KM)</mat-label>
                <input [readonly]="readOnly" name="stretchEndKM" matInput placeholder="Fim Trecho (KM)" [(ngModel)]="view['stretchEndKM']"
                  matTooltip="{{ view['stretchEndKM'] }}" #stretchEndKM="ngModel" stretchEndKM [dropSpecialCharacters]="false"
                  mask="000+000" [validation]="true"  />
                <mat-error *ngIf="stretchEndKM.invalid">Formato inválido o Fim do trecho (KM).</mat-error>
              </mat-form-field>

              <!-- Início Trecho (Lat, Long) -->
              <mat-form-field appearance="outline" fxFlex>
                <mat-label>Início Trecho (Lat, Long)</mat-label>
                <input [readonly]="readOnly" name="stretchStartLatLong" matInput placeholder="Início Trecho (Lat, Long)" matTooltip="{{ view['stretchStartLatLong'] }}"
                  [(ngModel)]="view['stretchStartLatLong']" [mask]="latLongMask" (paste)="onPasteLatLong($event, 'stretchStartLatLong')"
                  [patterns]="latLongPattern" [dropSpecialCharacters]="false" #stretchStartLatLong="ngModel" [validation]="true"/>
                <mat-error *ngIf="stretchStartLatLong.invalid">Formato inválido o Início do trecho (Lat, Long).</mat-error>
              </mat-form-field>

              <!-- Fim Trecho (Lat, Long) -->
              <mat-form-field appearance="outline" fxFlex>
                <mat-label>Fim Trecho (Lat, Long)</mat-label>
                <input [readonly]="readOnly" name="stretchEndLatLong" matInput placeholder="Fim Trecho (Lat, Long)" matTooltip="{{ view['stretchEndLatLong'] }}"
                  [(ngModel)]="view['stretchEndLatLong']" [mask]="latLongMask" (paste)="onPasteLatLong($event, 'stretchEndLatLong')"
                  [patterns]="latLongPattern" [dropSpecialCharacters]="false" #stretchEndLatLong="ngModel" [validation]="true"/>
                <mat-error *ngIf="stretchEndLatLong.invalid">Formato inválido o Fim do trecho (Lat, Long).</mat-error>
                </mat-form-field>

              <div class="form-spacer" fxFlex></div>
              <div class="form-spacer" fxFlex></div>
              <div class="form-spacer" fxFlex></div>
              <div class="form-spacer" fxFlex></div>

            </div>

            <div fxLayout="row wrap" fxLayoutGap=".5rem" fxLayoutGapRow=".5rem" class="pt-3 pl-3 pr-2">
              <mat-card fxFlex class="form-spacer pt-3 pl-3  mb-3 bg-shade-100 mat-elevation-z border">
                <app-upload class="app-upload" [filename]="view['filenameKmlRoute']" [readOnly]="readOnly"
                  file_label="KML de Rota" button_text="Selecionar..." file_icon="alt_route"
                  file_placeholder="Nenhum arquivo selecionado" (onFileSelected)="setKmlRouteFile($event)">
                </app-upload>
              </mat-card>
            </div>

            <header class="sipd-header">
              <span class="mat-body-2">Pontos</span>
              <div class="fill-space"></div>
              <div class="actions">
                <mat-label>Rota Automatizada</mat-label>
                <mat-slide-toggle class="my-4 px-4" name="authomatic" [checked]="view['autoRoute']" (change)="OnChangeAutomaticRoute()" [disabled]="readOnly">
                </mat-slide-toggle>
                <button mat-icon-button [matTooltip]= "editOnMap? 'Fechar Edição no Mapa': 'Editar no Mapa'" (click)="onEditOnMapClick()" [disabled]="readOnly">
                  <mat-icon fontset="material-icons-outlined">public</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Salvar no Cadastro"
                  (click)="onSavePointsToRegistrationClick()"
                  [disabled]="denySavePointsToRegistration || readOnly">
                  <mat-icon class="material-icons-outlined">shortcut</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Adicionar Ponto"  [matMenuTriggerFor]="sideMenu" [disabled]="readOnly">
                  <mat-icon class="material-icons-outlined">add</mat-icon>
                </button>
                <mat-menu #sideMenu="matMenu">
                  <button mat-menu-item (click)="onImportPointsClick()" [disabled]="readOnly">
                    <span>Importar do Cadastro...</span>
                  </button>
                  <button mat-menu-item (click)="onInsertPointClick()" [disabled]="readOnly">
                    <span>Inserir Lat/Long...</span>
                  </button>
                  <button mat-menu-item (click)="onKmlImportPointClick()" [disabled]="readOnly">
                    <span>Importar KML...</span>
                  </button>
                </mat-menu>
                <button mat-icon-button matTooltip="Editar Ponto Selecionado
                                                    (Somente 1 Ponto pode estar selecionado)"
                      [disabled]="this.pointSelection?.selected?.length !== 1 || readOnly"
                      (click)="onEditPointClick(this.pointSelection.selected[0])">
                  <mat-icon class="material-icons-outlined">edit</mat-icon>
                </button>
                <button mat-icon-button
                  matTooltip="Copiar Ponto Selecionado
                              (Somente 1 Ponto pode estar selecionado)"
                  [disabled]="this.pointSelection?.selected.length != 1 || readOnly"
                  (click)="onCopyPointClick(this.pointSelection.selected.pop())">
                  <mat-icon class="material-icons-outlined">file_copy</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Remover Pontos Selecionados"
                    [disabled]="this.pointSelection?.selected?.length == 0 || !canDeleteInspectionsPoints(this.pointSelection.selected) || readOnly"
                    (click)="onDeletePointsClick()">
                  <mat-icon class="material-icons-outlined">delete</mat-icon>
                </button>
                <mat-divider></mat-divider>
                <button mat-icon-button matTooltip="Mover para cima"
                    [disabled]="this.pointSelection?.selected?.length != 1 || readOnly"
                    (click)="onMovePointUp()">
                  <mat-icon class="material-icons-outlined">arrow_upward</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Mover para baixo"
                    [disabled]="this.pointSelection?.selected?.length != 1 || readOnly"
                    (click)="onMovePointDown()">
                  <mat-icon class="material-icons-outlined">arrow_downward</mat-icon>
                </button>
              </div>
            </header>
            <div class="px-3">
              <table mat-table [dataSource]="pointsDataSource" class="w-100 -outlined mb-5">

                <!-- Checkbox Column -->
                <ng-container matColumnDef="select" sticky>
                  <th class="txt-center fit-content" mat-header-cell *matHeaderCellDef>
                    <mat-checkbox (change)="$event ? masterTogglePoints() : null" color="accent"
                      [checked]="pointSelection.hasValue() && isAllSelectedPoints()" [disabled]="readOnly"
                      [indeterminate]="pointSelection.hasValue() && !isAllSelectedPoints()" [matTooltip]="checkboxTipPoints()">
                    </mat-checkbox>
                  </th>
                  <td class="txt-center fit-content" mat-cell *matCellDef="let row">
                    <mat-checkbox (click)="$event.stopPropagation()" color="accent" [disabled]="readOnly"
                      (change)="$event ? onCheckboxPointsClick(row) : null" [checked]="pointSelection.isSelected(row)"
                      [matTooltip]="checkboxTipPoints(row)">
                    </mat-checkbox>
                  </td>
                </ng-container>

                <!-- Base -->
              <ng-container matColumnDef="base">
                <th mat-header-cell *matHeaderCellDef width="100px"> Base </th>
                <td mat-cell *matCellDef="let element">
                  <mat-slide-toggle (click)="$event.stopPropagation()" color="accent" [disabled]="readOnly"
                    (change)="onChangeBasePoint(element)" [checked]="isBasePoint(element)">
                  </mat-slide-toggle>
                </td>
              </ng-container>

                <!-- Number Column -->
                <ng-container matColumnDef="number">
                  <th class="txt-center fit-content" mat-header-cell *matHeaderCellDef width="100px"> Num.
                  </th>
                  <td mat-cell *matCellDef="let element; let i = index">{{i + 1}}</td>
                </ng-container>

                <!-- ID Column -->
                <ng-container matColumnDef="id">
                  <th class="nowrap" mat-header-cell *matHeaderCellDef> ID </th>
                  <td class="nowrap" mat-cell *matCellDef="let element"> {{element?.inspectionPointId}} </td>
                </ng-container>

                <!-- Band Column -->
                <ng-container matColumnDef="band">
                  <th class="nowrap" mat-header-cell *matHeaderCellDef> Faixa </th>
                  <td class="nowrap" mat-cell class="ellipsis" style="max-width: 200px;" *matCellDef="let element" [matTooltip]="element?.band"> {{element?.band}} </td>
                </ng-container>

                <!-- Lat Long Column -->
                <ng-container matColumnDef="latlong">
                  <th class="nowrap" mat-header-cell *matHeaderCellDef> Lat, Long </th>
                  <td class="nowrap" mat-cell *matCellDef="let element"> {{element?.location?.latitude + ", " + element?.location?.longitude}} </td>
                </ng-container>

                <!-- KM Column -->
                <ng-container matColumnDef="km">
                  <th class="nowrap" mat-header-cell *matHeaderCellDef> KM </th>
                  <td class="nowrap" mat-cell *matCellDef="let element"> {{element?.km}} </td>
                </ng-container>

                <!-- Cidade Column -->
                <ng-container matColumnDef="city">
                  <th class="nowrap" mat-header-cell *matHeaderCellDef> Cidade </th>
                  <td class="nowrap" mat-cell *matCellDef="let element"> {{element?.city}} </td>
                </ng-container>

                <!-- UF Column -->
                <ng-container matColumnDef="state">
                  <th class="nowrap" mat-header-cell *matHeaderCellDef> UF </th>
                  <td class="nowrap" mat-cell *matCellDef="let element"> {{element?.state}} </td>
                </ng-container>

                <!-- Atividades Column -->
                <ng-container matColumnDef="activities">
                  <th class="nowrap" mat-header-cell *matHeaderCellDef width="500px"> Atividades </th>
                  <td class="nowrap" mat-cell class="ellipsis" style="max-width: 200px;" [matTooltip]="formatPointActivities(element?.activities)" *matCellDef="let element"> {{formatPointActivities(element?.activities)}} </td>
                </ng-container>

                <!-- Status -->
                <ng-container matColumnDef="status">
                  <th class="nowrap" mat-header-cell *matHeaderCellDef> Status </th>
                  <td class="nowrap" mat-cell *matCellDef="let element"> {{inspectionStatusTypeDescription[element?.status]}} </td>
                </ng-container>

                <!-- Access -->
                <ng-container matColumnDef="access">
                  <th class="nowrap" mat-header-cell *matHeaderCellDef> Sem Acesso </th>
                  <td class="nowrap" mat-cell *matCellDef="let element"> {{element?.inspectionPointNoAccess ? 'Sim': 'Não'}} </td>
                </ng-container>

                <!-- Form -->
                <ng-container matColumnDef="form-fill">
                  <th class="nowrap" mat-header-cell *matHeaderCellDef matTooltip="Algum Formulário Preenchido?"> Form. Preen. </th>
                  <td class="nowrap" mat-cell *matCellDef="let element"> {{element?.inspectionForms && element?.inspectionForms.length > 0? 'Sim': 'Não'}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="pointDisplayedColumns sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: pointDisplayedColumns; let element"
                  (contextmenu)="openContextMenu($event, element)"
                  [ngClass]="{ 'highlighted': pointSelection.isSelected(row) }">
                </tr>
              </table>

              <div #contextMenuTrigger style="visibility: hidden; position: fixed" [style.left]="contextMenuPosition.x"
                [style.top]="contextMenuPosition.y" [matMenuTriggerFor]="contextMenu">
              </div>

              <mat-menu #contextMenu="matMenu">
                <button mat-menu-item (click)="onEditPointClick(this.contextMenuSelectedItem)" [disabled]="readOnly">
                  <mat-icon class="material-icons-outlined">edit</mat-icon>
                  <span>Editar Ponto</span>
                </button>
                <button mat-menu-item (click)="onCopyPointClick(this.contextMenuSelectedItem)" [disabled]="readOnly">
                  <mat-icon class="material-icons-outlined">file_copy</mat-icon>
                  <span>Copiar Ponto</span>
                </button>
                <button mat-menu-item (click)="onDeletePointClick(this.contextMenuSelectedItem)" [disabled]="!canDeleteInspectionPoint(this.contextMenuSelectedItem) || readOnly">
                  <mat-icon class="material-icons-outlined">delete</mat-icon>
                  <span>Remover Ponto</span>
                </button>
                <button mat-menu-item (click)="onSaveToRegistrationClick(this.contextMenuSelectedItem)" [disabled]="!canSaveToRegistration(this.contextMenuSelectedItem) || readOnly">
                  <mat-icon class="material-icons-outlined">shortcut</mat-icon>
                  <span>Salvar no Cadastro</span>
                </button>
              </mat-menu>

            </div>
          </div>
        </form>
      </mat-tab>

      <!-- Resultados (Tab) -->
      <mat-tab label="Resultados">
        <form class="edit-form">
          
              <div fxLayout="row wrap" fxLayoutGap=".5rem" fxLayoutGapRow="0" class="pt-3 pl-3 pr-2">
                
                <mat-form-field appearance="outline" class="no-hint-text mat-form-field-readonly" fxFlex>
                  <!-- Data/Hora de Criação-->
                  <mat-label>Data/Hora (Criação)</mat-label>
                  <input matInput mask="d0/M0/0000 Hh:m0" name="createdAt" [(ngModel)]="view['createdAt']" readonly>
                </mat-form-field>

                <mat-form-field appearance="outline" class="no-hint-text mat-form-field-readonly" fxFlex>
                  <!-- Data/Hora de Solicitação -->
                  <mat-label>Data/Hora (Solicitação)</mat-label>
                  <input matInput mask="d0/M0/0000 Hh:m0" name="sentDate" [(ngModel)]="view['sentDate']" readonly>
                </mat-form-field>

                <mat-form-field appearance="outline" class="no-hint-text mat-form-field-readonly" fxFlex>
                  <!-- Data/Hora de Início do Percurso -->
                  <mat-label>Data/Hora Início do Percurso</mat-label>
                  <input matInput mask="d0/M0/0000 Hh:m0" name="startDateMovement" [(ngModel)]="view['startDateMovement']" matTooltip="{{ 'Primeira vez que foi iniciado o percurso de um ponto da verificação' }}"readonly>
                </mat-form-field>

                <mat-form-field appearance="outline" class="no-hint-text mat-form-field-readonly" fxFlex>
                  <!-- Data/Hora de Início da inspeção -->
                  <mat-label>Data/Hora Início da Inspeção</mat-label>
                  <input matInput mask="d0/M0/0000 Hh:m0" name="startDateInspection" [(ngModel)]="view['startDateInspection']" matTooltip="{{ 'Primeira vez que foi iniciada a inspeção de um ponto da verificação' }}" readonly>
                </mat-form-field>

                <mat-form-field appearance="outline" class="no-hint-text mat-form-field-readonly" fxFlex>
                  <!-- Data/Hora de Fim inspeção -->
                  <mat-label>Data/Hora Fim da Inspeção</mat-label>
                  <input matInput mask="d0/M0/0000 Hh:m0" name="endDateInspection" [(ngModel)]="view['endDateInspection']" matTooltip="{{ 'Última vez que foi concluída a inspeção de um ponto da verificação' }}" readonly>
                </mat-form-field>

                <mat-form-field appearance="outline" class="no-hint-text mat-form-field-readonly" fxFlex>
                  <!-- Mídia-->
                  <mat-label>Mídia</mat-label>
                  <input matInput  name="midia"  [value]="view['midia']==null ?'': view['midia']"  matTooltip="{{ 'Houve uma troca de mídia ( áudio, foto, vídeo) nas mensagens durante a verificação' }}" readonly>
                </mat-form-field>

                <single-autocomplete [data]="evaluationValues" placeholder="Avaliação" [(valueData)]="view['evaluation']"
                  [disabled]="readOnly" matTooltip="{{ view['evaluation'] }}" fxFlex>
                </single-autocomplete>

                <mat-form-field appearance="outline" class="no-hint-text mat-form-field-readonly" fxFlex>
                  <!-- Pontos Planejados-->
                  <mat-label>Pontos Planejados</mat-label>
                  <input matInput  name="numPoints" [value]="view['inspections']?.length" placeholder="Pontos Planejados" matTooltip="{{ view['inspections']?.length }}" readonly>
                </mat-form-field>

                <mat-form-field appearance="outline" class="no-hint-text mat-form-field-readonly" fxFlex>
                  <!-- Pontos Executados-->
                  <mat-label>Pontos Executados</mat-label>
                  <input matInput  name="numExecutedPoints" [value]="finishedPoints > 0 ? finishedPoints : '0'" placeholder="Pontos Executados" matTooltip="{{ 'Quantidade de pontos que não tem acesso ou foram inspecionados (dentro da cerca eletrônica)' }}" readonly>
                </mat-form-field>
  
                <mat-form-field appearance="outline" class="no-hint-text mat-form-field-readonly" fxFlex>
                  <!-- Diferença de Pontos-->
                  <mat-label>Diferença de Pontos</mat-label>
                  <input matInput  name="differenceOfPoints" [value]="view['inspections'].length - finishedPoints" placeholder="Diferença de Pontos" matTooltip="{{ 'Pontos Planejados - Pontos executados' }}" readonly>
                </mat-form-field>                

                <mat-form-field appearance="outline" class="no-hint-text mat-form-field-readonly" fxFlex>
                  <!-- Pontos Sem Acesso-->
                  <mat-label>Pontos sem Acesso</mat-label>
                  <input matInput  name="numPointsWithoutAccess" [value]="this.noAccessPointsDataSource.data.length" placeholder="Pontos sem Acesso" matTooltip="{{ this.noAccessPointsDataSource.data.length }}" readonly>
                </mat-form-field>
                
                <mat-form-field appearance="outline" class="no-hint-text mat-form-field-readonly" fxFlex>
                  <!-- KM Planejado-->
                  <mat-label>KM Planejado</mat-label>
                  <input matInput  name="kmPlanned" [value]="view['kmPlanned'] | number : '1.2-2'" placeholder="KM Planejado" matTooltip="{{ view['kmPlanned'] | number : '1.2-2'}}" readonly>
                </mat-form-field>

                <mat-form-field appearance="outline" class="no-hint-text mat-form-field-readonly" fxFlex>
                  <!-- KM Executado -->
                  <mat-label>KM Executado</mat-label>
                  <input matInput  name="kmPlanned" [value]="view['kmExecuted'] | number : '1.2-2'" placeholder="KM Executado" matTooltip="{{ view['kmExecuted'] | number : '1.2-2'}}" readonly>
                </mat-form-field>

                <mat-form-field appearance="outline" class="no-hint-text mat-form-field-readonly" fxFlex>
                  <!-- Diferença de KM -->
                  <mat-label>Diferença de KM</mat-label>
                  <input matInput  name="kmPlanned" [value]="((view['kmExecuted']>0 ||view['kmPlanned']>0) ? view['kmPlanned']-view['kmExecuted']: 0) | number : '1.2-2'" placeholder="Diferença de KM" readonly>
                </mat-form-field>

                <mat-form-field appearance="outline" class="no-hint-text mat-form-field-readonly" fxFlex>
                  <!-- Data/Hora Fim verificação -->
                  <mat-label>Data/Hora Fim da Verificação</mat-label>
                  <input matInput mask="d0/M0/0000 Hh:m0" name="dateSendEndVerification" [(ngModel)]="view['dateSendEndVerification']" matTooltip="{{ 'Data/Hora em que a verificação foi concluída' }}" readonly>
                </mat-form-field>

                <mat-form-field appearance="outline" class="no-hint-text mat-form-field-readonly" fxFlex>
                  <!-- Data/Hora de App (Recebida) -->
                  <mat-label>Data/Hora App (Recebida)</mat-label>
                  <input matInput mask="d0/M0/0000 Hh:m0" name="receivedDateApp" [(ngModel)]="view['receivedDateApp']" matTooltip="{{ 'Data/Hora em que a verificação foi recebida na App' }}" readonly>
                </mat-form-field>

                <mat-form-field appearance="outline" class="no-hint-text mat-form-field-readonly" fxFlex>
                  <!-- Data/Hora Quadro Encontrado Enviado -->
                  <mat-label>Data/Hora Quadro Encontrado (Enviado) </mat-label>
                  <input matInput mask="d0/M0/0000 Hh:m0" name="dateSendedSituationFound" [(ngModel)]="view['dateSendedSituationFound']" matTooltip="{{ 'Data/Hora em que o quadro encontrado foi enviado' }}" readonly>
                </mat-form-field>

                <mat-form-field appearance="outline" class="no-hint-text mat-form-field-readonly" fxFlex>
                  <!-- Data/Hora Quadro Encontrado Recebido -->
                  <mat-label>Data/Hora Quadro Encontrado (Recebido) </mat-label>
                  <input matInput mask="d0/M0/0000 Hh:m0" name="dateSituationFound" [(ngModel)]="view['dateSituationFound']" matTooltip="{{ 'Data/Hora em que o quadro encontrado foi recebido' }}" readonly>
                </mat-form-field>

                 <!-- Liberação de faixa -->
                <single-autocomplete [data]="verificationBandReleaseDescription | keyvalue" placeholder="Liberação de Faixa" name="value" optionValue="key"
                  [(valueData)]="view['bandRelease']"
                  [disabled]="readOnly" matTooltip="{{ verificationBandReleaseDescription[view['bandRelease']] }}" fxFlex>
                </single-autocomplete>


                <div class="w-100 px-3">
                  <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Quadro Encontrado</mat-label>
                    <textarea name="situationFound" matInput [(ngModel)]="view['situationFound']" placeholder="Quadro Encontrado" rows="5" [readonly]="true"></textarea>
                  </mat-form-field>
                </div>
                
                <ng-container *ngIf="isHiddenInformeGerencial">
                <!-- Notas da aba resultado -->
                <div class="w-100 px-3">
                  <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Notas</mat-label>
                    <textarea name="textResultObservations" matInput [(ngModel)]="view['resultObservations']" placeholder="Notas" rows="5" [readonly]="readOnly"></textarea>
                  </mat-form-field>
                </div>
                
                <!-- Informe Gerencial da aba resultado -->
                <div class="w-100 px-3 outer">
                  <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Informe Gerencial</mat-label>
                    <textarea name="textManagementReport" matInput [(ngModel)]="view['managementReport']" placeholder="Notas" rows="5" [readonly]="readOnly"></textarea>
                    <div class="xmlIcon">
                      <button mat-raised-button (click)="sendManagementReport()" [disabled]="!this.model?.id">Enviar</button>
                    </div>
                  </mat-form-field>
                </div>   
              </ng-container>

                <mat-form-field appearance="outline" class="no-hint-text mat-form-field-readonly" fxFlex>
                  <!-- TACCPD-->
                  <mat-label>TACCPD</mat-label>
                  <input matInput  name="taccpd"  placeholder="TACCPD"  [value]="view['taccpd'] ? view['taccpd'] : standadFormatTime" matTooltip="{{ 'Tempo de atendimento entre o início do alerta e a solicitação para verificação/inspeção PD.' }}" readonly>
                </mat-form-field>

                <mat-form-field appearance="outline" class="no-hint-text mat-form-field-readonly" fxFlex>
                  <!-- TACS-->
                  <mat-label>TACS</mat-label>
                  <input matInput  name="tacs"  placeholder="tacs" [value]="view['tacs'] ? view['tacs'] : standadFormatTime " matTooltip="{{ 'Tempo entre a criação do registro e a solicitação para verificação/inspeção PD.' }}" readonly>
                </mat-form-field>

                <mat-form-field appearance="outline" class="no-hint-text mat-form-field-readonly" fxFlex>
                  <!-- TAPD-->
                  <mat-label>TAPD</mat-label>
                  <input matInput  name="tapd"  placeholder="tapd" [value]="view['tapd'] ? view['tapd'] : standadFormatTime" matTooltip="{{ 'Tempo de atendimento para verificação/inspeção PD.' }}" readonly>
                </mat-form-field>

                <mat-form-field appearance="outline" class="no-hint-text mat-form-field-readonly" fxFlex>
                  <!-- TTA-->
                  <mat-label>TTA</mat-label>
                  <input matInput  name="tta"  placeholder="tta"  [value]="view['tta'] ? view['tta'] : standadFormatTime " matTooltip="{{ 'Tempo total de atendimento.' }}" readonly>
                </mat-form-field>

                <mat-form-field appearance="outline" class="no-hint-text mat-form-field-readonly" fxFlex>
                  <!-- TERF-->
                  <mat-label>TERF</mat-label>
                  <input matInput  name="terf"  placeholder="terf" [value]="view['terf'] ? view['terf'] : standadFormatTime" matTooltip="{{ 'Tempo de entrega do relatório final.' }}" readonly>
                </mat-form-field>

                <mat-form-field appearance="outline" class="no-hint-text mat-form-field-readonly" fxFlex>
                  <!-- TVI-->
                  <mat-label>TVI</mat-label>
                  <input matInput  name="tvi"  placeholder="tvi" [value]="view['tvi'] ? view['tvi'] : standadFormatTime" matTooltip="{{ 'Tempo de verificação/inspeção.' }}" readonly>
                </mat-form-field>

                <mat-form-field appearance="outline" class="no-hint-text mat-form-field-readonly" fxFlex>
                  <!-- TSI-->
                  <mat-label>TSI</mat-label>
                  <input matInput  name="tsi"  placeholder="tsi" [value]="view['tsi'] ? view['tsi'] : standadFormatTime" matTooltip="{{ 'Tempo entre solicitação e inicio da Inspeção.' }}" readonly>
                </mat-form-field>

                <mat-form-field appearance="outline" class="no-hint-text mat-form-field-readonly" fxFlex>
                  <!-- TRIVI-->
                  <mat-label>TRIVI</mat-label>
                  <input matInput  name="trivi"  placeholder="trivi" [value]="view['trivi'] ? view['trivi'] : standadFormatTime"  matTooltip="{{ 'Tempo de resposta para início da verificação/inspeção.' }}"readonly>
                </mat-form-field>
             </div>
            </form>

            <header class="sipd-header">
              <span class="mat-body-2">Pontos sem acesso</span>
              <div class="fill-space"></div>          
            </header>

            <div class="px-3">
              <table mat-table [dataSource]="noAccessPointsDataSource" class="w-100 -outlined mb-5">          
    
              <!-- Number Column -->
              <ng-container matColumnDef="number">
                <th class="txt-center fit-content" mat-header-cell *matHeaderCellDef width="100px"> Num.
                </th>
                <td mat-cell *matCellDef="let element">{{element?.number}}</td>
              </ng-container>          
    
                <!-- Band Column -->
                <ng-container matColumnDef="band">
                  <th class="nowrap" mat-header-cell *matHeaderCellDef> Faixa </th>
                  <td class="nowrap" mat-cell class="ellipsis" style="max-width: 200px;" *matCellDef="let element" [matTooltip]="element?.band"> {{element?.band}} </td>
                </ng-container>
    
                <!-- KM Column -->
                <ng-container matColumnDef="km">
                  <th class="nowrap" mat-header-cell *matHeaderCellDef> KM </th>
                  <td class="nowrap" mat-cell *matCellDef="let element"> {{element?.km}} </td>
                </ng-container>
    
                <!-- Lat Long Column -->
                <ng-container matColumnDef="latlong">
                  <th class="nowrap" mat-header-cell *matHeaderCellDef> Lat, Long </th>
                  <td class="nowrap" mat-cell *matCellDef="let element"> {{element?.location?.latitude + ", " + element?.location?.longitude}} </td>
                </ng-container>           
    
                <tr mat-header-row *matHeaderRowDef="noAccessPointDisplayedColumns sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: noAccessPointDisplayedColumns; let element"                            >
                </tr>
              </table> 
                       
            </div>         
      </mat-tab>

    <mat-tab label="Formulários">
      <app-answer-list #listAnswerOperation [operationId]="model && model['id'] ? model['id'] : '[NOVA]'" [editReadOnly]="readOnly" [operationType]="'VERIFICATION'"></app-answer-list>
    </mat-tab>
      <!-- Anexos (Tab) -->
    <mat-tab label="Anexos">
      <form class="edit-form">

        <div fxLayout="row wrap" fxLayoutGap=".5rem" fxLayoutGapRow=".5rem" class="pt-3 pl-3 pr-2">
          <mat-card fxFlex class="form-spacer pt-3 pl-3 mb-3 bg-shade-100 mat-elevation-z border">
            <app-upload class="app-upload" [filename]="view['attachmentFile1']" [seeFile]="seeFile" 
              file_label="Anexo 1" button_text="Selecionar" file_icon="file_open"
              file_placeholder="Nenhum arquivo selecionado" (onFileSelected)="addFile($event, attachmentNumber.ONE)" (viewFileSelected)="openOperationFile($event, attachmentNumber.ONE)">
            </app-upload>
          </mat-card>          
        </div>

        <div fxLayout="row wrap" fxLayoutGap=".5rem" fxLayoutGapRow=".5rem" class="pt-3 pl-3 pr-2">
          <mat-card fxFlex class="form-spacer pt-3 pl-3 mb-3 bg-shade-100 mat-elevation-z border">
            <app-upload class="app-upload" [filename]="view['attachmentFile2']" [seeFile]="seeFile" 
              file_label="Anexo 2" button_text="Selecionar" file_icon="file_open"
              file_placeholder="Nenhum arquivo selecionado" (onFileSelected)="addFile($event, attachmentNumber.TWO)" (viewFileSelected)="openOperationFile($event, attachmentNumber.TWO)">
            </app-upload>
          </mat-card>
        </div>

        <div fxLayout="row wrap" fxLayoutGap=".5rem" fxLayoutGapRow=".5rem" class="pt-3 pl-3 pr-2">
          <mat-card fxFlex class="form-spacer pt-3 pl-3 mb-3 bg-shade-100 mat-elevation-z border">
            <app-upload class="app-upload" [filename]="view['attachmentFile3']" [seeFile]="seeFile" 
              file_label="Anexo 3" button_text="Selecionar" file_icon="file_open"
              file_placeholder="Nenhum arquivo selecionado" (onFileSelected)="addFile($event, attachmentNumber.THREE)" (viewFileSelected)="openOperationFile($event, attachmentNumber.THREE)">
            </app-upload>
          </mat-card>
        </div>

        <div fxLayout="row wrap" fxLayoutGap=".5rem" fxLayoutGapRow=".5rem" class="pt-3 pl-3 pr-2">
          <mat-card fxFlex class="form-spacer pt-3 pl-3 mb-3 bg-shade-100 mat-elevation-z border">
            <app-upload class="app-upload" [filename]="view['attachmentFile4']" [seeFile]="seeFile" 
              file_label="Anexo 4" button_text="Selecionar" file_icon="file_open"
              file_placeholder="Nenhum arquivo selecionado" (onFileSelected)="addFile($event,attachmentNumber.FOUR)" (viewFileSelected)="openOperationFile($event,attachmentNumber.FOUR)">
            </app-upload>
          </mat-card>
        </div>

        <div fxLayout="row wrap" fxLayoutGap=".5rem" fxLayoutGapRow=".5rem" class="pt-3 pl-3 pr-2">
          <mat-card fxFlex class="form-spacer pt-3 pl-3 mb-3 bg-shade-100 mat-elevation-z border">
            <app-upload class="app-upload" [filename]="view['attachmentFile5']" [seeFile]="seeFile" 
              file_label="Anexo 5" button_text="Selecionar" file_icon="file_open"
              file_placeholder="Nenhum arquivo selecionado" (onFileSelected)="addFile($event, attachmentNumber.FIVE)" (viewFileSelected)="openOperationFile($event, attachmentNumber.FIVE)">
            </app-upload>
          </mat-card>
        </div>
      </form>
    </mat-tab>
    
    <!-- Informe Gerencial (Tab) -->
    <ng-container *ngIf="isHiddenInformeGerencial">
      <mat-tab class=" display: block;" label="Informe Gerencial" >
        <form class="edit-form">
          <div fxLayout="row wrap" fxLayoutGap=".5rem" fxLayoutGapRow="0" class="pt-3 pl-3 pr-2">
            <!-- Notas da aba resultado -->
            <div class="w-100 px-3">
              <mat-form-field appearance="outline" fxFlex>
                <mat-label>Notas</mat-label>
                <textarea name="textResultObservations" matInput [(ngModel)]="view['resultObservations']" placeholder="Notas"
                  rows="5" [readonly]="true"></textarea>
              </mat-form-field>
            </div>
      
            <!-- Informe Gerencial da aba resultado -->
            <div class="w-100 px-3 outer">
              <mat-form-field appearance="outline" fxFlex>
                <mat-label>Informe Gerencial</mat-label>
                <textarea name="textManagementReport" matInput [(ngModel)]="view['managementReport']" placeholder="Notas"
                  rows="5" [readonly]="true"></textarea>                
              </mat-form-field>
            </div>
          </div>
        </form>        
      </mat-tab>      
    </ng-container>
    </mat-tab-group>
  </div>
  <app-global-spinner class="sipd-spinner" diameter="40" [class.mat-elevation-z8]="true" strokeWidth="4" color="accent" [showSpinner]="getShowSpinner()"></app-global-spinner>
</div>
