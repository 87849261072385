<div mat-dialog-title>
    <span>{{patrolRepetitionModel?.id == null? 'Criar ': 'Editar '}} Repetição de Ronda: {{patrolRepetitionModel?.referencePatrolIdentifier}} - {{patrol?.name}}</span>
    <button mat-icon-button mat-dialog-close matTooltip="Cancelar" class="ml-auto">
        <mat-icon class="material-icons-outlined">close</mat-icon>
    </button>
</div>

<div mat-dialog-content>
    <mat-card class="dialog-repetition-section">
        <label class="dialog-subtitle">Início</label>
        <div fxLayout="row wrap" fxLayoutGap=".5rem" fxLayoutGapRow="0" class="ml-4">
            <date-chooser placeholder="Data Inicial" [(valueData)]="viewStartDate"
                #initialdate="dateChooserContext" (dateChangedEvent)="applyStartDate($event)" [required]='true' fxFlex>
            </date-chooser>
            <mat-form-field fxFlex appearance="outline" class="no-bottom">
                <mat-label>Hora Inicial:</mat-label>
                <input matInput mask="Hh:m0" name="viewStartTime" placeholder="Hora Inicial" required
                    (keyup)="applyStartTime($event.target.value)" [dropSpecialCharacters]="false"
                    [leadZeroDateTime]="true" validation="false" [(ngModel)]="viewStartTime" #initialtime="ngModel">
                <mat-icon matSuffix>query_builder</mat-icon>
                <mat-error>Campo Obrigatório</mat-error>
            </mat-form-field>
        </div>
    </mat-card>

    <mat-card class="dialog-repetition-section">
        <label class="dialog-subtitle">Repetição</label>
        <mat-radio-group [(ngModel)]="startRule" (change)="changeStartRule()">
            <div class="pb-1 ml-4" fxLayout="row wrap">
                <mat-radio-button class="mr-4" style="width:100px" [value]="predictedDialogRuleOption.EVERY_PERIOD" >A cada:</mat-radio-button>
                
                <div *ngIf="startRule === predictedDialogRuleOption.EVERY_PERIOD">
                    <input matInput type="number" min="1" style="width:40px" class="mr-1" [(ngModel)]="theEachQuantity" />
                    <mat-select [(value)]="unitPeriodSelected" [(ngModel)]="unitPeriodSelected" style="width: 100px;" class="mr-4">
                      <mat-option [value]="option.unit" *ngFor="let option of periodTypesView" [selected]="unitPeriodSelected === option.unit" >{{ option.viewValue }}</mat-option>
                    </mat-select>
                </div>
            </div>

            <div class="pb-1 ml-4" fxLayout="row wrap">
                <mat-radio-button class="mr-4" style="width:100px" [value]="predictedDialogRuleOption.IN_THE_DAYS">Nos dias:</mat-radio-button>

                <div *ngIf="startRule === predictedDialogRuleOption.IN_THE_DAYS" fxLayout="row">
                    <div *ngFor="let weekDay of listweek$ | async" class="pr-2">
                        <mat-checkbox (change)='selectDaysOfWeek(weekDay.value, $event)' [checked]="selectedDaysOfWeek[weekDay.value - 1]" ></mat-checkbox>
                        <label class="pl-2">{{weekDay.viewValue}}</label>
                    </div>
                </div>
            </div>
        </mat-radio-group>
        
    </mat-card> 
        
    <mat-card class="dialog-repetition-section">
        <label id="finish-group-label" class="dialog-subtitle">Término</label>
        <mat-radio-group aria-labelledby="finish-group-label" [(ngModel)]="finishRule" (change)="changeFinishRule()">
            <!-- <div class="pb-1 ml-4" fxLayout="row wrap">
                <mat-radio-button class="mr-4" style="width:100px" [value]="predictedDialogRuleOption.NEVER_FINISH">Nunca</mat-radio-button>
            </div> -->

            <div class="pb-1 ml-4" fxLayout="row wrap">
                <mat-radio-button class="mr-4" style="width:100px" [value]="predictedDialogRuleOption.FINISH_AFTER_QUANTITY">Após:</mat-radio-button>

                <div *ngIf="finishRule === predictedDialogRuleOption.FINISH_AFTER_QUANTITY" fxLayout="row">
                    <input matInput type="number" min="1" style="width:40px" class="mr-1" [(ngModel)]="afterRepetitionQuantity" />
                    <mat-label *ngIf="afterRepetitionQuantity>1">repetições</mat-label>
                    <mat-label *ngIf="afterRepetitionQuantity==1">repetição</mat-label>
                </div>
            </div>

            <div class="pb-1 ml-4" fxLayout="row wrap">
                <mat-radio-button class="mr-4" style="width:100px; padding-bottom:3em;" [value]="predictedDialogRuleOption.FINISH_DATE">No Dia:</mat-radio-button>

                <div [hidden]="finishRule !== predictedDialogRuleOption.FINISH_DATE" fxLayout="row">
                    <date-chooser placeholder="Data Final" [(valueData)]="viewEndDateFilter"
                        #finaldate="dateChooserContext" (dateChangedEvent)="applyEndDate($event)" [required]="finishRule === predictedDialogRuleOption.FINISH_DATE" fxFlex>
                    </date-chooser>
                </div>
            </div>
        </mat-radio-group>
    </mat-card>
</div>

<mat-dialog-actions align="end">  
    <button mat-raised-button (click)="onSaveClick()" [disabled]="finaldate.invalid || initialdate.invalid || initialtime.invalid">Salvar</button>
    <button mat-raised-button (click)="onRemoveClick()" [hidden]="patrolRepetitionModel?.id == null">Remover</button>
    <button mat-raised-button mat-dialog-close>Cancelar</button>
</mat-dialog-actions>
